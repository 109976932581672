import React, { Component } from 'react'

const Playlist = () => (
    <div className="container" style={{marginTop:'2rem'}}>
        <div className="columns">
            <div className="column is-two-thirds">
                <div className="card">
                    <div className="card-content">
                        <h2 className="subtitle">Wedding Playlist</h2>
                        <p>Some text can go here...</p>
                    </div>
                </div>
            </div>
            <div className="column is one-third">
                <iframe 
                    allow="autoplay *; encrypted-media *;" 
                    frameborder="0" 
                    height="450" 
                    style={{
                        width:'100%',
                        maxWidth:'660px',
                        overflow:'hidden',
                        background:'transparent'
                    }} 
                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" 
                    src="https://embed.music.apple.com/us/playlist/vanbury-wedding-mix/pl.u-JjNZFNKpjW"
                ></iframe>
            </div>
        </div>
    </div>
);

export default Playlist;