import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-google'

const INITIAL_STATE = {
	days:0,
	hours:0,
	minutes:0,
	seconds:0
};

class Ticker extends Component {
	constructor(props) {
		super(props);
		this.state = INITIAL_STATE;
		this.interval = setInterval(() => {
			const s=1000, m=s*60, h=m*60, d=h*24;

			let target = new Date('Apr 30, 2022 16:00:00').getTime();
			let now = new Date().getTime();
			let remaining = target - now;

			this.setState({
				days:Math.floor(remaining / d),
				hours:Math.floor((remaining % d) / h),
				minutes:Math.floor((remaining % h) / m),
				seconds:Math.floor((remaining % m) / s)
			});
		}, 1000);
	}
	
	render() {
		const { days, hours, minutes, seconds } = this.state;
		return(
			<div className="columns is-mobile ticker mt-1">
				<div className="column">
					<span id="d">{days}</span>days
				</div><div className="column">
					<span id="h">{hours}</span>hours
				</div><div className="column">
					<span id="m">{minutes}</span>minutes
				</div><div className="column">
					<span id="s">{seconds}</span>seconds
				</div>
			</div>
		);
	}
}

const Landing2 = () => (
	<div className="landing">
		<div className="container">
			<div className="card fixed-sm">
				<div className="card-content">
					<h2 className="subtitle has-text-centered">Sign in</h2>
			
					<p className="link">
						<a href="#">Don't have an account yet?</a>
					</p>
				</div>
			</div>
		</div>
	</div>
);

class Landing extends Component {
	constructor(props) {
		super(props);
		this.iAmNotARobot = this.iAmNotARobot.bind(this);
		this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
		this.verifyCallback = this.verifyCallback.bind(this);
	}

	componentDidMount() {
		if( localStorage.getItem('VanburyWeddingToken') )
			window.location.href = '/our-story'
		else {	
			loadReCaptcha();

			if( this.captcha ) {
				this.captcha.reset();
			}
		}
	}

	iAmNotARobot() {
		if( this.captcha ) {
			this.captcha.reset();
			this.captcha.execute();
		}
	}

	onLoadRecaptcha() {
		if( this.captcha ) {
			this.captcha.reset();
		}
	}

	verifyCallback(token) {
		localStorage.setItem('VanburyWeddingToken', token);
		window.location.href = '/our-story'
	}

	render() {
		return (<div className="landing">
			<div className="container">
				<h1 className="title">#VanburyWedding</h1>
				<p className="title-font">4 - 30 - 2022</p>
				<Ticker />

				<ReCaptcha 
					ref = {(el) => {this.captcha = el;}}
					size="invisible"
					render="explicit"
					sitekey="6LeZ7XkdAAAAAFOzmyjjEFs0mVjICHL-rGAw9neH"
					onloadCallback={this.onLoadRecaptcha}
					verifyCallback={this.verifyCallback}
				/>
				<div className="cta">
					<p><button className="button is-large" onClick={this.iAmNotARobot}>Enter</button></p>
				</div>
			</div>
		</div>);
	}
}

export default Landing;

export { Ticker, Landing2 };