import React, { Component } from 'react'
import { NavLink, withRouter } from "react-router-dom"

class Navbar extends Component {
	constructor(props) {
		super(props);

		this.signOut = this.signOut.bind(this);
	}

	signOut() {
		localStorage.removeItem('VanburyWeddingToken');
		this.props.history.push("/")
	}

	render() {
		return (<div>
			<nav className="navbar has-text-centered" role="navigation" aria-label="main navigation">
				<div className="navbar-menu is-active">
					<div className="navbar-start">
						<NavLink to="/our-story" className="navbar-item" activeClassName="is-active">Our Story</NavLink>
						<NavLink to="/wedding-party" className="navbar-item" activeClassName="is-active">Wedding Party</NavLink>
						<NavLink to="/info" className="navbar-item" activeClassName="is-active">Information</NavLink>
						<NavLink to="/photos" className="navbar-item" activeClassName="is-active">Photos</NavLink>
					</div>
				</div>
			</nav>
		</div>);
	}
}

export default withRouter(Navbar);