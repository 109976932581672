import React from 'react'
import { 
	BrowserRouter as Router,
	Route,
	Switch
} from 'react-router-dom'
import Landing, { Ticker } from './components/Landing'
import OurStory from './components/OurStory'
import WeddingParty from './components/WeddingParty'
import Info from './components/Info'
import FAQs from './components/FAQs'
import Photos from './components/Photos';
import Playlist from './components/Playlist'
import RollCall from './components/RollCall'
import ProtectedRoute from './components/ProtectedRoute'

const App = () => (
	<Router>
		<Switch>
			<Route exact path="/" component={Landing} />
			<ProtectedRoute exact path="/our-story" component={OurStory} isProtected={true} hasNavigation={true} />
			<ProtectedRoute exact path="/wedding-party" component={WeddingParty} isProtected={true} hasNavigation={true} />
			<ProtectedRoute exact path="/info" component={Info} isProtected={true} hasNavigation={true} />
			<ProtectedRoute exact path="/photos" component={Photos} isProtected={true} hasNavigation={true} />
			<ProtectedRoute exact path="/qs-and-as" component={FAQs} isProtected={true} hasNavigation={true} />
			<ProtectedRoute exact path="/playlist" component={Playlist} isProtected={true} hasNavigation={false} />
			<ProtectedRoute exact path="/rollcall" component={RollCall} isProtected={true} hasNavigation={true} />
			<ProtectedRoute component={E404} />
		</Switch>
	</Router>
);

const E404 = () => (
	<div className="card">
		<div className="card-content">
			<h2 className="subtitle">Whoops!</h2>
			<p>Looks like you are trying to access a page that doesn't exist; try checking your link and trying again. If you continue getting 
				this message, please let us know at <a href="mailto:TheHappyCouple@VanburyWedding.com" className="link">TheHappyCouple@VanburyWedding.com</a>.</p>
		</div>
	</div>
)

export default App;