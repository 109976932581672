import React, { Component } from 'react'
import Sidebar from '../Sidebar'
import portrait from '../../imgs/wedding_party/bride_and_groom.jpg'
import painting from '../../imgs/wedding_party/painting.jpg'

class OurStory extends Component {
	constructor(props) {
		super(props);
		this.child = React.createRef();
	}

	render() {
		return (
			<div className="container" style={{marginTop:'2rem'}}>
				<div className="columns">
					<div className="column is-two-thirds">
						<div className="card">
							<div className="card-sticker">
								<figure className="image">
									<img src={portrait} className="is-rounded" />
								</figure>
							</div>
							<div className="card-content">
								<h2 className="subtitle">Our Story</h2>

								<h4 className="title2">Prologue</h4>
								<p>Once upon a time, a boy messaged a girl about a book series on a dating app. Five years later, they’re getting married.</p>

								<h4 className="title2">Chapter 1: The First Date</h4>
								<p>On Hannah and Lucas’ first date, they met for sushi at what would become their favorite restaurant: Rockwell Republic in downtown 
									Grand Rapids. In addition to books, they discovered a shared love of music, animals, and even got into an argument about Harry Potter. 
									That first date lasted three and a half hours.</p>

								<h4 className="title2">Chapter 2: The First I Love You</h4>
								<p>During their first year together, they went to concerts nearly every weekend and explored their favorite restaurants 
									and bars around Grand Rapids. Their time together was so good, it was like living in a real-life romance novel. For Lucas’ birthday, 
									Hannah planned to give Lucas a thoughtful and memorable birthday present: The first "I love you." Except Hannah is very bad at waiting 
									and told him two days early. (She got him a Batman lamp for his birthday instead.)</p>

								<h4 className="title2">Chapter 3: The First Apartment</h4>
								<p>A few short months later, Hannah and her cat Jezebel moved in with Lucas, his "dog-ter" Siria, and his cat Sebastian; they officially became the Vanbury 
									household. Lucas wasn’t quite prepared for the number of books that Hannah came with nor with the scope of living with a rather grouchy 
									Jezebel, but after turning the guest room into a library/cat room, they made it work. </p>

								<h4 className="title2">Chapter 4: The First Secret</h4>
								<p>The following spring, their family of five became a family of six as they adopted a German Shepherd puppy named Wulfric. The Vanbury family 
									moved out of their first apartment and bought their first home together. One of their favorite parts of that house was the beautiful 
									backyard and deck . . . which is where Lucas proposed a year later.</p>

								<p>Ever the sneak, Lucas had been designing an engagement ring in secret for weeks with a local jeweler. While Hannah was in New York on a 
									business trip, Lucas even got her dad’s blessing (being careful not to ask "permission" so as not to offend Hannah's staunchly feminist values). 
									And Hannah’s dad managed to keep it a secret for two months!</p>

								<p>Lucas’ plan was to propose on his birthday but he too is bad at waiting, so he ended up proposing a day early. Hannah and Lucas got engaged 
									on their back deck with Siria and Wulfric at their sides (one of them puking) as Sebastian and Jezebel watched from the window.</p>

								<h4 className="title2">Chapter 5: Happily Ever After</h4>
								<p>A year after their engagement, Lucas and Hannah sold their first house and bought their forever home on Lake Michigan where they have plenty 
									of space for all their books, two now fully grown German Shepherds, and two still rather grouchy cats. Although they’ve traded nights out at 
									concerts and bars for quiet nights in watching the Food Network, one thing hasn’t changed at all: Lucas and Hannah cannot wait to get married 
									and live happily ever after.</p>

								<h4 className="title2">Chapter 6: Six Months Later...</h4>
								<p>Lucas and Hannah tied the knot in a private ceremony on the sunny beaches of Riveria Maya in Mexico on November 2, 2021. All sixteen wedding 
									guests tore it up on the dance floor at the reception that night. A month later, Lucas and Hannah honeymooned in LA and Mexico, spending a few 
									days at Disneyland and then cruising down the Mexican Riveria on the west coast of Mexico. The happy couple are excited to celebrate with you 
									in our hometown of Holland, MI.</p>
							</div>
						</div>
					</div>
					<div className="column">
						<Sidebar ref={this.child} imgOverride={painting} />
					</div>
				</div>
			</div>
		);
	}
}

export default OurStory;