import React, { Component } from 'react'
import Sidebar from '../Sidebar'

class Info extends Component {
	constructor(props) {
		super(props);
		this.child = React.createRef();
	}

	render() {
		return (
			<div className="container" style={{marginTop:'2rem'}}>
				<div className="columns">
					<div className="column is-two-thirds">
						<div className="card">
							<div className="card-content">
								<h2 className="subtitle">Information</h2>
								<div className="content">
									<p>We tied the knot in a private ceremony in November, but we are excited to celebrate with
										friends and family in Michigan this Spring. Our celebration will be held at 
										the <a className="link" href="https://maps.apple.com?daddr=2223+South+Shore+Drive+Macatawa+MI+49434"  target="_blank" rel="noopener noreferrer">
											Eldean Shipyard
										</a> in Holland, Michigan on April 30<sup>th</sup>, 2022.</p>

									<p>There will be a catered, buffet-style dinner starting at 4pm. Please <a href="mailto:thehappycouple@vanburywedding.com">reach out to us</a> if
										you have any dietary restrictions.</p>

									<p>Please RSVP by March 30<sup>th</sup>, so that we can be sure to have enough food for everyone.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="column">
						<Sidebar ref={this.child} />
					</div>
				</div>
			</div>
		);
	}
}

export default Info;