import React, { Component } from 'react'
import { withFirebase } from '../Firebase'
import Loader from '../Loader'

class RollCall extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			yes: [],
			no: [],
			maybe: [],
			blank: [],
			isLoading: true
		}
	}

	componentDidMount() {
		this.unsubscribeYes = this.props.firebase.guests().where("rsvp", "==", "Yes").orderBy("last").orderBy("first").onSnapshot(snap => {
			var yes = []

			snap.forEach((d) => {
				var x = d.data();
				var n = x.first + " " + x.last + (x.code && x.code != '' ? ' *' : '') + (x.last_accessed ? ' †' : '')

				if( n.length > 4 )
					yes.push(n);
			});

			this.setState({ yes })
		});
		this.unsubscribeNo = this.props.firebase.guests().where("rsvp", "==", "No").orderBy("last").orderBy("first").onSnapshot(snap => {
			var no = []

			snap.forEach((d) => {
				var x = d.data();
				var n = x.first + " " + x.last + (x.code && x.code != '' ? ' *' : '') + (x.last_accessed ? ' †' : '')

				if( n.length > 4 )
					no.push(n);
			});

			this.setState({ no })
		});
		this.unsubscribeMaybe = this.props.firebase.guests().where("rsvp", "==", "Not sure").orderBy("last").orderBy("first").onSnapshot(snap => {
			var maybe = []

			snap.forEach((d) => {
				var x = d.data();
				var n = x.first + " " + x.last + (x.code && x.code != '' ? ' *' : '') + (x.last_accessed ? ' †' : '')

				if( n.length > 4 )
					maybe.push(n);
			});

			this.setState({ maybe })
		});
		this.unsubscribeBlank = this.props.firebase.guests().where("rsvp", "==", "").orderBy("last").orderBy("first").onSnapshot(snap => {
			var blank = []

			snap.forEach((d) => {
				var x = d.data();
				var n = x.first + " " + x.last + (x.code && x.code != '' ? ' *' : '') + (x.last_accessed ? ' †' : '')

				if( n.length > 4 )
					blank.push(n);
			});

			this.setState({ blank, isLoading: false })
		});
	}

	componentWillUnmount() {
		this.unsubscribeYes();
		this.unsubscribeNo();
		this.unsubscribeMaybe();
		this.unsubscribeBlank();
	}

	render() {
		return (<div className="card" style={{ height:'100%', marginTop:'2rem' }}>
			<div className="card-content">
				{this.state.isLoading && <Loader />}
				{!this.state.isLoading && (<div className="columns">
					<div className="column">
						<h4 class="title2">Yes ({this.state.yes.length})</h4>
						<table className="table">
							<tbody>
								{this.state.yes.map(d => <tr key={d}><td>{d}</td></tr>)}
							</tbody>
						</table>
					</div>
					<div className="column">
						<h4 class="title2">No ({this.state.no.length})</h4>
						<table className="table">
							<tbody>
								{this.state.no.map(d => <tr key={d}><td>{d}</td></tr>)}
							</tbody>
						</table>
					</div>
					<div className="column">
						<h4 class="title2">Not Sure ({this.state.maybe.length})</h4>
						<table className="table">
							<tbody>
								{this.state.maybe.map(d => <tr key={d}><td>{d}</td></tr>)}
							</tbody>
						</table>
					</div>
					<div className="column">
						<h4 class="title2">Blank ({this.state.blank.length})</h4>
						<table className="table">
							<tbody>
								{this.state.blank.map(d => <tr key={d}><td>{d}</td></tr>)}
							</tbody>
						</table>
					</div>
				</div>)}
			</div>
		</div>)
	}
}

export default withFirebase(RollCall);