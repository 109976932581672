import React, { Component } from 'react'
import Loader from '../Loader'
import map from '../../imgs/map.png'

class Sidebar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<div className="card card-img">
					{ this.props.imgOverride && <img src={this.props.imgOverride} /> }
					{ !this.props.imgOverride && <img src={map} />}
				</div>
				<div className="card">
					<div className="card-content">
						<h2 className="subtitle" style={{marginBottom:'.2rem'}}>Eldean Shipyard</h2>
						<a className="link" href="https://maps.apple.com?daddr=2223+South+Shore+Drive+Macatawa+MI+49434"  target="_blank" rel="noopener noreferrer">
							<address>
								2223 South Shore Drive<br />
								Macatawa, MI 49434
							</address>
						</a>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<h2 className="subtitle">Registry</h2>
						<p>We are lucky enough to already have nearly everything we need for this new chapter together! However, for friends 
							and family who have expressed an interest, we are registered at <a className="link" href="https://www.potterybarn.com/registry/tvn6xdxxnw/registry-list.html" target="_blank" rel="noopener noreferrer">Pottery Barn</a> and <a className="link" href="https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/551227151?eventType=Wedding" target="_blank" rel="noopener noreferrer">Bed Bath &amp; Beyond</a>.</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Sidebar;