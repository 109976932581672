import React, { Component } from 'react'
import Sidebar from '../Sidebar'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

const imgs = {};
const thumbs = {};
function importAll(r,t) {
	r.keys().forEach(key => (t ? thumbs[key] = r(key) : imgs[key] = r(key)));
}
importAll(require.context("../../imgs/wedding_pics", false, /\.(png|jpe?g|svg)$/), false);
importAll(require.context("../../imgs/wedding_pics/thumbs", false, /\.(png|jpe?g|svg)$/), true);
const images = Object.entries(imgs).map(module => module[1].default);
const thumbnails = Object.entries(thumbs).map(module => module[1].default);
const getDim = (p,o) => {
	var x = p.split('/').pop().split('.')[0].split('_')[1];
	var y = x.split('x');

	return y[o];
}

const MyGallery = () => (
	<Gallery shareButton={false}>
		<div className="gallery">
			{images.map((o,i) => (
				<Item 
					key={i}
					original={o}
					thumbnail={thumbnails[i]}
					width={getDim(o,0)}
					height={getDim(o,1)}
				>
					{({ref,open}) => (
						<img ref={ref} onClick={open} src={thumbnails[i]} />
					)}
				</Item>
			))}
		</div>
	</Gallery>
)

class Photos extends Component {
	constructor(props) {
		super(props);
		this.child = React.createRef();
	}

	render() {
		return (
			<div className="container" style={{marginTop:'2rem'}}>
				<div className="columns">
					<div className="column is-two-thirds">
						<div className="card">
							<div className="card-content">
								<h2 className="subtitle">Photos</h2>

								<MyGallery />
							</div>
						</div>
					</div>
					<div className="column">
						<Sidebar ref={this.child} />
					</div>
				</div>
			</div>
		);
	}
}

export default Photos;