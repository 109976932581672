import React, { Component } from 'react'
import Sidebar from '../Sidebar'
import maid_of_honor from '../../imgs/wedding_party/maid_of_honor.jpg'
import best_man from '../../imgs/wedding_party/best_man.jpg'
import bridesmaid1 from '../../imgs/wedding_party/bridesmaid1.jpg'
import groomsman1 from '../../imgs/wedding_party/groomsman1.jpg'
import bridesmaid2 from '../../imgs/wedding_party/bridesmaid2.jpg'
import groomsman2 from '../../imgs/wedding_party/groomsman2.jpg'
import painting from '../../imgs/wedding_party/painting2.jpg'

class WeddingParty extends Component {
	constructor(props) {
		super(props);
		this.child = React.createRef();
	}

	render() {
		return (
			<div className="container" style={{marginTop:'2rem'}}>
				<div className="columns">
					<div className="column is-two-thirds">
						<div className="card">
							<div className="card-content">
								<h2 className="subtitle">Meet the Wedding Party</h2>

								<div className="media">
									<figure className="media-left">
										<p className="image is-256x256">
											<img className="is-rounded" src={maid_of_honor} />
										</p>
									</figure>
									<div className="media-content">
										<h4 className="title2">Olivia VanVels <small>Maid of Honor</small></h4>
										<p>As sisters, I've known Hannah all my life. We've grown closer as we have gotten older but our relationship sprouted 
											from the small things siblings go through: Watching TV shows like America’s Next Top Model, Sailor Moon, and 
											Dragon Ball Z; our nightly DDR sessions; playing video games together (I did eventually figure out that my 
											controller was never plugged in and the game was one player. . .); and sharing Pasta Roni and chicken kiev. 
											Hannah's reliability, willingness to share, kindness, and love throughout the years makes her a great sister, 
											friend, daughter, and partner.</p>
									</div>
								</div>

								<div className="media">
									<div className="media-content">
										<h4 className="title2 has-text-right-desktop">Caleb Ausbury <small>Best Man</small></h4>
										<p className="has-text-right-desktop">I am Lucas’ brother. While younger in age I am taller, which makes him my little 
											brother. Throughout most of our childhood our relationship consisted mostly of fighting over toys and the attention of 
											others, though we would occasionally unite either against a common enemy (usually parents) or to do something fun (and often 
											stupid, like Paperclip Roulette). In adulthood our relationship became much more cordial, and we really only fight 
											about a few controversial topics I assume most families fight about (like if Divination is a legitimate academic 
											subject in the world of Harry Potter; it isn’t). He has become a good friend and we often enjoy playing a variety 
											of games, eating gourmet food, watching unbelievably terrible movies, and discussing all things nerdy. I have been 
											a huge Hannah fan from the first thirty minutes of meeting her, when we discussed the Documentary Hypothesis, and she has 
											since seamlessly fit in with the family. I am honored to stand at Lucas’ side to make her addition formal.</p>
									</div>
									<figure className="media-right">
										<p className="image is-256x256">
											<img className="is-rounded" src={best_man} />
										</p>
									</figure>
								</div>

								<div className="media">
									<figure className="media-left">
										<p className="image is-256x256">
											<img className="is-rounded" src={bridesmaid2} />
										</p>
									</figure>
									<div className="media-content">
										<h4 className="title2">Leah VanVels <small>Bridesmaid</small></h4>
										<p>Growing up with Hannah and two other sisters meant that there were a lot of girls to play with Barbie dolls and dress 
											up. It meant fighting over who had to wash, dry, or put away the dishes because, as our mom would say, “Who needs a 
											dishwasher when you already have six little dish washers?” It meant trying to do each other’s makeup with the brightest 
											pink blush we could find in Mom’s makeup drawer. Hannah and I are similar in the fact that I sometimes did what she 
											already achieved; I wanted to be great just like her. I won the American hero essay contest at school three years 
											after she did, and both of us went to a university for undergraduate degrees. Some things never change because, to 
											this day, I still look up to and admire her and all of her accomplishments.</p>
									</div>
								</div>

								<div className="media">
									<div className="media-content">
										<h4 className="title2 has-text-right-desktop">Jake Wright <small>Groomsman</small></h4>
										<p className="has-text-right-desktop">I am Lucas’ dearest cousin, trusted adviser, devoted friend, fearless Leader, Jedi Master, 
											and Breaker of Chains. Over the years Lucas and I have bonded over rock concerts, Quentin Tarantino movies, countless 
											Lord of the Rings and South Park references, and all-nighters playing Legend of Zelda (while occasionally convincing our 
											parents to buy us pizza at 2 AM). I’ve always considered Lucas a brother growing up and still do to this day. It already feels 
											like Hannah is part of the family, but I look forward to many more years of Mario Party bonding sessions. I promise I won’t cry 
											during the wedding. <strong>*starts crying*</strong></p>
									</div>
									<figure className="media-right">
										<p className="image is-256x256">
											<img className="is-rounded" src={groomsman1} />
										</p>
									</figure>
								</div>

								<div className="media">
									<figure className="media-left">
										<p className="image is-256x256">
											<img className="is-rounded" src={bridesmaid1} />
										</p>
									</figure>
									<div className="media-content">
										<h4 className="title2">Lily Maatman <small>Bridesmaid</small></h4>
										<p>I am Hannah’s youngest sister. Hannah actually got ordained and married my husband and me. Without her we couldn’t 
											have said “I Do!” Hannah has been there for me since the womb. She babysat me when I was little, and we would bake Funfetti 
											cakes and make a mess trying to make cake balls (which is still one of our favorite treats). When she went to college and 
											grad school and moved out of state I was always there for her: lending a helping hand and moving her furniture out of the several 
											apartments she’s had. Things won’t ever change between us. She’s got my back, and I’ve got hers. Yes, if she would ever need another 
											couch moved, she can count on me. I don’t think I would have passed Geometry in high school without her helping me with my homework. 
											She will always be a genius, she will always be my concert buddy, and she will always beat me at Mario Kart. </p>
									</div>
								</div>

								<div className="media">
									<div className="media-content">
										<h4 className="title2 has-text-right-desktop">Ian Teninty <small>Groomsman</small></h4>
										<p className="has-text-right-desktop">Hannah is my sister-in-law and Lucas will be my brother-in-law. I have known Hanah and Luke 
											for 3 years, and it has been an honor to get to know them. I've enjoyed not only spending time with them, but also their two 
											german shepherd children :)! It's awesome that Lucas and I share a very similar sense of humor, and I can count on him to appreciate 
											my memes. I can't wait to officially have a brother who is a great cook, a generous host, and a great friend. I'll be counting down 
											the seconds until I get to see Hanah and Lucas say "I do."</p>
									</div>
									<figure className="media-right">
										<p className="image is-256x256">
											<img className="is-rounded" src={groomsman2} />
										</p>
									</figure>
								</div>
							</div>
						</div>
					</div>
					<div className="column">
						<Sidebar ref={this.child} imgOverride={painting} />
					</div>
				</div>
			</div>
		);
	}
}

export default WeddingParty;