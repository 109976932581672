import React, { Component } from 'react'
import { withFirebase } from '../Firebase'
import { Redirect, Link } from 'react-router-dom'
import Navbar from '../Navbar'
import { Ticker } from '../Landing'
import Loader from '../Loader'

class ProtectedRoute extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			isLoading: true
		}
	}

	componentWillMount() {
		var token = localStorage.getItem('VanburyWeddingToken');

		if( token && token.length > 10 )
			this.setState({ 
				'isAuthenticated':true,
				'isLoading': false
			})
		else
			this.setState({ 
				'isAuthenticated':false,
				'isLoading': false
			})
	}
	
	render() {
		const Component = this.props.component;
		const hasNavigation = this.props.hasNavigation;
		const isProtected = this.props.isProtected;

		return (<div style={{height:'100%'}}>
			<header>
				<Link to="/" className="title">#VanburyWedding</Link>
				<p className="title-font" style={{fontSize:'1.25rem'}}>April 30th, 2022</p>
				<Ticker />
			</header>
			<section>
				<div className="container">
					{this.state.isLoading && (<Loader />)}
					{!this.state.isLoading && (!isProtected || this.state.isAuthenticated) && (<div>
						{hasNavigation && <Navbar />}

						<Component {...this.props} isAuthenticated={this.state.isAuthenticated} />
					</div>)}
					{!this.state.isLoading && isProtected && !this.state.isAuthenticated && (<Redirect to="/" />)}
				</div>
			</section>
		</div>)
	}
}

export default withFirebase(ProtectedRoute);