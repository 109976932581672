import React, { Component } from 'react'
import Sidebar from '../Sidebar'

class FAQs extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    render() {
        return (
            <div className="container" style={{marginTop:'2rem'}}>
                <div className="columns">
                    <div className="column is-two-thirds">
                        <div className="card">
                            <div className="card-content">
                                <h2 className="subtitle">Qs and As</h2>

                                <h4 className="title2">Do I have to stay at this resort in order to attend?</h4>
                                <p>Unfortunately, the answer is probably yes. We are alloted a very limited number of guests who are allowed
                                    to stay off-site, but that number is determined by total guests. If you are interested in staying off-site,
                                    please reach out to us and ask but we may not have availability and, even if we think we do, we won't know
                                    for sure until bookings are locked in August.</p>
                                <p>Please note that anyone who does end up being allowed to attend while staying off-site, will be required to 
                                    purchase a day-pass in order to enter the property at a cost of $100/person.</p>

                                <h4 className="title2">What days should I stay?</h4>
                                <p>The wedding is on Tuesday, November 2<sup>nd</sup> and the minimum
                                    stay is 3 nights, but which 3+ nights you'd like to stay is entirely up to you. We are
                                    staying Saturday, October 30<sup>th</sup> through Saturday, 
                                    November 6<sup>th</sup>.</p>

                                <h4 className="title2">How much will I have to pay?</h4>
                                <p>Rates depend on the room type you select and how long you choose to stay, however only the deposit is 
                                    required in order to book your room. The deposit is $100 for 1-2 person rooms and $150 for 3 person
                                    rooms.</p>

                                <h4 className="title2">What are my options for rooms?</h4>
                                <p>The rooms themselves are all pretty similar; they are similar in size and all come with
                                    a hydrotub in the room. The biggest differences are the view, whether it is a swim out room or
                                    comes with a soaking tub on the terrace/balcony, and the room's location.</p>
                                <p>You can <a className="link" href="http://www.secretsresorts.com/en_us/resorts/mexico/maroma-beach-riviera-cancun/accommodations.html" target="_blank" rel="noopener noreferrer">
                                        check out the different room options in more depth on Secrets' website</a>, or <a 
                                            className="link" 
                                            href="http://www.secretsresorts.com/content/dam/amr/sites/secrets/resort-maps/ground-map/SEMRC.png" 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                        >look over a map of the resort</a>.</p>
                                <p className="note">Swim up rooms are all tropical view unless you want to pay for the Presidential 
                                    Suite. If an Ocean View is important to you, check out the Ocean View or Ocean Front suites.</p>
                                <p className="note">There are a limited number of room types we are able to have listed in our group;
                                    if you would like to book a room type that you see on the Secrets website but isn't listed as an
                                    option, or if you'd like to change a booking after the fact,  
                                    please <a className="link" onClick={() => this.child.current.contactCoord()}>reach out to our travel coordinator</a>.</p>
                                
                                <h4 className="title2">What is Preferred Club?</h4>
                                <p><a className="link" onClick={() => this.child.current.preferredClub()}>While there are some perks</a> that come with the 
                                    Preferred Club rooms, in our opinion the biggest perk is that those rooms are the closest to the ocean.</p>
                                <p>A Preferred Club room will either be a swim out room or will have a terrace/balcony with a soaking 
                                    tub.</p>

                                <h4 className="title2">How does payment work?</h4>
                                <p>In order to reserve your room and dates, only a deposit is necessary. After that, you can make payments
                                    of any size you like as often as you like using the same link. If you'd like to schedule automatic
                                    payments of a set amount, please <a className="link" onClick={() => this.child.current.contactCoord()}>contact our travel coordinator</a>.</p>

                                <h4 className="title2">When is final payment due?</h4>
                                <p>That depends on if you purchase travel insurance or not. If you purchase travel insurance, you must be
                                    paid-in-full by August 18<sup>th</sup>, 2021. If you choose to opt out of travel insurance, your final
                                    bill will be due July 22<sup>nd</sup>, 2021.</p>

                                <h4 className="title2">Do I have to buy travel insurance? How much is it?</h4>
                                <p>Travel insurance is not required, but it is highly recommended. The price depends on the price of your
                                    room but should be between $109 and $139 per person. If you choose to purchase travel insurance, you must
                                    do so at the time of booking; you cannot add it on later.</p>

                                <h4 className="title2">What if I would like to cancel or change my booking?</h4>
                                <p>You can cancel or change your booking up until July 22<sup>nd</sup>, 2021 with no penalty. After that,
                                    you may be charged a penalty. Please <a className="link" onClick={() => this.child.current.contactCoord()}>contact our travel 
                                    coordinator</a> for more specific answers.</p>

                                <h4 className="title2">Where are you registered?</h4>
                                <p>Your presence is all the gift we need but, if you would like to honor us with something more, we  
                                    are <a className="link" href="https://www.potterybarn.com/registry/tvn6xdxxnw/registry-list.html" target="_blank" rel="noopener noreferrer">registered at Pottery Barn</a>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <Sidebar ref={this.child} />
                    </div>
                </div>
            </div>
        );
    }
}

export default FAQs;