import React, { Component } from 'react'

const Loader = () => (
    <div className="ring-loader">
        <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<g>
				<path className="fa-secondary" d="M130.92 101.84L96 32l32-32h96l32 32-34.92 69.84a176.91 176.91 0 0 0-90.16 0zM350 160.56a207.16 207.16 0 0 1 29.06 72 111.89 111.89 0 1 1-96.46 4.95q-1.5-4.65-3.4-9.14l-.08-.2c-.39-.9-.78-1.8-1.19-2.69a3.54 3.54 0 0 0-.16-.34c-.41-.9-.84-1.79-1.27-2.68l-.09-.18q-1.44-2.91-3.06-5.73v-.07c-.5-.87-1-1.72-1.52-2.57-.11-.18-.22-.36-.34-.54-.43-.71-.87-1.41-1.33-2.11-.13-.21-.27-.42-.41-.63-.46-.7-.92-1.39-1.4-2.07L268 208q-1.8-2.58-3.74-5l-.48-.59c-.48-.61-1-1.21-1.47-1.81l-.67-.8c-.45-.53-.91-1.06-1.37-1.58l-.72-.83c-.49-.53-1-1.06-1.47-1.59l-.66-.72c-.71-.75-1.44-1.5-2.17-2.24a114.18 114.18 0 0 0-9.1-8.13 176.23 176.23 0 0 0-79.63 198.89A175.18 175.18 0 0 0 184 424.69q1.11 1.91 2.26 3.77c.76 1.24 1.54 2.46 2.34 3.68a.21.21 0 0 0 0 .06q1.18 1.82 2.42 3.6l.05.07q1.23 1.79 2.51 3.54c.85 1.18 1.73 2.35 2.61 3.5q1.33 1.75 2.72 3.46A176 176 0 1 0 350 160.56z"></path>
				<path className="fa-primary" d="M199 446.5a176 176 0 1 1 94-43 80.87 80.87 0 0 1-13.56-10.91 79.37 79.37 0 0 1-22.32-43.33 112 112 0 1 0-90.59 34.36A175.41 175.41 0 0 0 199 446.5z"></path>
			</g>
		</svg>
        Loading...
    </div>
);
    
export default Loader;